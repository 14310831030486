//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Tag, Button } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  generateDefaultShiftInvoice,
  generateInvoiceRequestBody,
  generateSubcontractorInvoiceRequestBody,
} from "./invoiceUtils";
import _groupBy from "lodash/groupBy";
import { SERVICES, add, get, update } from "../../../../redux/apis/global";
import { CalendarModalBody } from "../../../client/NewPlanning/Calendar.styles";
import Modal from "../../../../components/Feedback/Modal";
import { State } from "../../../../redux/root-reducer";
import InputController from "../../../../form/InputController";
import InvoiceGeneral from "./InvoiceGeneral";
import InvoiceSpecification from "./InvoiceSpecification";
import InvoiceEquipment from "./InvoiceEquipment";
import currencies from "../currencies";
import SelectController from "../../../../form/SelectController";
import { Typography } from "@material-ui/core";
import { TableViews } from "../../../Tables/AntTables/AntTables";
import DatePickerController from "../../../../form/DatePickerController";
import { DiffHours, timeZoneMoment } from "../../../../redux/apis/utils";
import { Shift } from "../../../../redux/apis/types";
import {
  MargePercentage,
  MargeRate,
  getInvoiceShiftClientRate,
} from "../../invoices/invoiceUtils";
import moment from "moment";
import SubcontractorInvoiceTotal from "./SubcontractorInvoiceTotal";

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  onCreate?: (data: any) => void;
  data: any;
};

export default function ({ modalVisible, onClose, onCreate, data }: Props) {
  const [loading, setLoading] = useState(false);

  const currencyList = useMemo(() => {
    return Object.keys(currencies);
  }, []);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      shifts: generateDefaultShiftInvoice(data?.data?.work),
      vat: data?.data?.vat || 21,
      amount: data?.data?.amount || 0,
      total_amount: data?.data?.total || 0,
      currency: data?.currency || "EUR",
      invoice_number: data?.invoice_number || "",
      invoice_date: moment(data?.invoice_date) || moment(),
    },
  });
  const { handleSubmit, watch, setValue } = methods;

  const amount = watch("amount");
  const vat = watch("vat");
  const shifts = watch("shifts");

  useEffect(() => {
    if (!!vat && !!amount) {
      setValue(
        "total_amount",
        (
          parseFloat(amount) * parseFloat(vat) * 0.01 +
          parseFloat(amount)
        ).toFixed(2)
      );
    }
  }, [vat, amount]);

  const visible = modalVisible ? true : false;
  if (!visible) return null;

  const onSave = async (obj: any) => {
    setLoading(true);

    if (!obj.shifts[0].subcontractor_id) {
      alert("Subcontractor is missing!");
      return;
    }

    if (data?.id) {
      const body = generateSubcontractorInvoiceRequestBody(obj);
      const request = {
        type: "debit",
        ...body,
        id: data?.id,
      };

      const resp = await update(
        `${SERVICES.INVOICE}/subcontractor`,
        null,
        request
      );
      setLoading(false);
      if (resp?.message) {
        alert(resp?.message);
      } else onCreate();
    } else {
      const body = generateSubcontractorInvoiceRequestBody(obj);
      const request = {
        type: "debit",
        ...body,
      };

      const resp = await add(`${SERVICES.INVOICE}`, request);
      setLoading(false);
      if (resp) {
        onCreate();
      }
    }

    // if (resp && onCreate) {
    //   onCreate({
    //     invoice_number: request.invoice_number,
    //     invoice_id: resp.invoice_id,
    //     branch_id: data[0]?.branch_id,
    //     client_id: data[0]?.client_id,
    //   });
    // }
  };

  const columns = [
    {
      title: "Date",
      render: (text: string, record: Shift) => (
        <div style={{ fontWeight: "bold" }}>
          {moment(record.start).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "Client",
      render: (text: string, record: Shift) => (
        <div>{record?.planning?.branch?.name}</div>
      ),
    },
    {
      title: "Client Invoice nr.",
      render: (text: string, record: Shift) => (
        <div>{record.planning.invoice?.invoice_number || "N/A"}</div>
      ),
    },
    {
      title: "Location",
      render: (text: string, record: Shift) => (
        <div>{record.planning.location?.name || "N/A"}</div>
      ),
    },
    {
      title: "From",
      render: (text: string, record: Shift) => (
        <div>
          {timeZoneMoment(
            record.start,
            record?.planning.location?.timezone
          ).format("HH:mm")}
        </div>
      ),
    },
    {
      title: "Till",
      render: (text: string, record: Shift) => (
        <div>
          {timeZoneMoment(
            record.end,
            record?.planning.location?.timezone
          ).format("HH:mm")}
        </div>
      ),
    },
    {
      title: "Hours",
      render: (text: string, record: Shift) => (
        <div>{DiffHours(record.start, record.end).toFixed(2)}</div>
      ),
    },
    {
      title: "Sales costs",
      render: (text: string, record: Shift) => (
        <div>{getInvoiceShiftClientRate(record.planning)}</div>
      ),
    },
    {
      title: "Expected Purchase costs",
      render: (text: string, record: Shift, index: number) => (
        <div>
          <InputController
            name={`shifts.${index}.purchase_cost`}
            placeholder="type here"
            rules={{
              required: true,
            }}
            type="phone"
          />
        </div>
      ),
    },
    {
      title: "Marge per hour",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost = watch(`shifts.${index}.purchase_cost`);
        return <div>{MargeRate(record, 1, purchaseCost)}</div>;
      },
    },
    {
      title: "Sales costs",
      render: (text: string, record: Shift) => (
        <div>
          {getInvoiceShiftClientRate(
            record.planning,
            DiffHours(record.start, record.end)
          )}
        </div>
      ),
    },
    {
      title: "Purchase costs",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost = watch(`shifts.${index}.purchase_cost`);

        return (
          <div>
            {purchaseCost
              ? parseFloat(purchaseCost) * DiffHours(record.start, record.end)
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Total Marge",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost = watch(`shifts.${index}.purchase_cost`);

        return (
          <div>
            {MargeRate(
              record,
              DiffHours(record.start, record.end),
              purchaseCost
            )}
          </div>
        );
      },
    },
    {
      title: "Marge %",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost = watch(`shifts.${index}.purchase_cost`);
        return (
          <div>
            {MargePercentage(
              record,
              DiffHours(record.start, record.end),
              purchaseCost
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        title={"Subcontractor(s) Invoice"}
        visible={visible}
        confirmLoading={loading}
        onOk={handleSubmit(onSave)}
        onCancel={onClose}
        okText={"Save"}
        cancelText="Cancel"
        width="100%"
        style={{ top: 20 }}
      >
        <CalendarModalBody>
          <FormProvider {...methods}>
            <form>
              <Row gutter={20}>
                <Col md={3} sm={24} xs={24}>
                  <b>Invoice Date</b>{" "}
                  <DatePickerController
                    rules={{ required: true }}
                    name="invoice_date"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="invoice_number"
                    style={{ width: "100%" }}
                    rules={{ required: true }}
                    label="Invoice Number"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <SelectController
                    name="currency"
                    options={currencyList}
                    rules={{ required: true }}
                    label="Currency"
                  />
                </Col>

                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="amount"
                    type="number"
                    rules={{ required: true }}
                    label="Amount"
                  />
                </Col>

                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="vat"
                    type="number"
                    rules={{ required: true }}
                    label="VAT %"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="total_amount"
                    type="number"
                    rules={{ required: true }}
                    label="Total amount"
                  />
                </Col>
              </Row>

              <Row gutter={40} style={{ margin: 10 }}>
                <div>
                  <Typography style={{ marginRight: 5 }} variant="h6">
                    Shift(s) History
                  </Typography>

                  <TableViews.SimpleView
                    style={{ marginBottom: 10 }}
                    dataSource={data?.data?.work || []}
                    columns={columns}
                  />
                </div>
              </Row>
              <SubcontractorInvoiceTotal total={amount} shifts={shifts} />
            </form>
          </FormProvider>
        </CalendarModalBody>
      </Modal>
    </div>
  );
}
