// @ts-ignore
import { Row, Tag } from "antd";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { TableViews } from "../../../Tables/AntTables/AntTables";
import React, { useMemo } from "react";
import { Shift } from "../../../../redux/apis/types";
import {
  MargeRate,
  getInvoiceShiftClientRate,
  getInvoiceShiftSubcRate,
} from "../../invoices/invoiceUtils";
import { DiffHours } from "../../../../redux/apis/utils";
import { useFormContext } from "react-hook-form";

type Props = {
  total?: number;
  shifts: Shift[];
  source?: string;
};
const SubcontractorInvoiceTotal = ({ total, shifts, source }: Props) => {
  const context = useFormContext();
  const getTotalSubcontractorInvoice = (
    shifts: Shift[],
    total: number | undefined
  ) => {
    let data = {
      sales_costs: 0,
      purchase_cost: 0,
      total_purchase_cost: 0,
      expected_total_purchase_cost: 0,
      total_sales_costs: 0,
      marge: 0,
      total_marge: 0,
      total_hours: 0,
      currency: "EUR",
    };

    if (shifts[0].planning?.branch_rate?.currency)
      data["currency"] = shifts[0].planning?.branch_rate?.currency;

    for (let i = 0; i < shifts.length; i++) {
      let purchaseCost = 0;
      if (context) {
        purchaseCost = parseFloat(context.watch(`shifts.${i}.purchase_cost`));
      } else {
        purchaseCost = parseFloat(
          getInvoiceShiftSubcRate(
            shifts[i],
            DiffHours(shifts[i].start, shifts[i].end)
          )
        );
      }

      data["sales_costs"] += parseFloat(
        getInvoiceShiftClientRate(shifts[i].planning)
      );
      data["total_hours"] += DiffHours(shifts[i].start, shifts[i].end);
      data["total_sales_costs"] += parseFloat(
        getInvoiceShiftClientRate(
          shifts[i].planning,
          DiffHours(shifts[i].start, shifts[i].end)
        )
      );

      if (purchaseCost) {
        data["purchase_cost"] += purchaseCost;
        data["expected_total_purchase_cost"] +=
          purchaseCost * DiffHours(shifts[i].start, shifts[i].end);
        data["marge"] += parseFloat(
          MargeRate(shifts[i], 1, purchaseCost + "") + ""
        );
      }
    }

    data["total_marge"] =
      data["total_sales_costs"] - data["expected_total_purchase_cost"];

    if (total) {
      data["total_marge"] = data["total_sales_costs"] - total;
    }

    return [data];
  };

  const data = getTotalSubcontractorInvoice(shifts, total);

  const columns = [
    {
      title: "Total Hours",
      render: (text: string, record: any) => (
        <div>{record.total_hours.toFixed(2)}</div>
      ),
    },
    {
      title: "Total Sales costs",
      render: (text: string, record: any) => (
        <div>
          {record.total_sales_costs.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Expected Purchase costs",
      render: (text: string, record: any, index: number) => (
        <div>
          {record.expected_total_purchase_cost.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Total Purchase costs",
      render: (text: string, record: any, index: number) => (
        <div>{total ? total + " " + record.currency : "N/A"}</div>
      ),
      hidden: source === "overview",
    },
    {
      title: "Total Marge",
      render: (text: string, record: any, index: number) => (
        <div>
          {record.total_marge.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Status",
      hidden: source === "overview",
      render: (text: string, record: any, index: number) => {
        if (!total) return <Tag color="warning">Unknown</Tag>;

        // const different = record.total_purchase_cost - total;
        if (record.total_marge >= 0) {
          return <Tag color="success">Checked</Tag>;
        }

        return <Tag color="error">Not Checked</Tag>;
      },
    },
  ];

  return (
    <Row gutter={40} style={{ marginTop: 10, justifyContent: "center" }}>
      <Typography style={{ marginRight: 5, alignSelf: "center" }} variant="h6">
        Total
      </Typography>
      <TableViews.SimpleView
        style={{ marginBottom: 10 }}
        dataSource={data}
        columns={columns.filter((item) => !item.hidden)}
      />
    </Row>
  );
};

export default SubcontractorInvoiceTotal;
